import { Button, Result, Row, Space } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const UnauthorizedPage = () => (
  <div>
    <Result status="500" title="401" subTitle="Sorry, you are unauthorized." />
    <Row justify="center">
      <Space align="center">
        <Button type="primary">
          <Link to="/login">Login</Link>
        </Button>
      </Space>
    </Row>
  </div>
);

export default UnauthorizedPage;
